<script>
	import { onMount } from 'svelte';
	import { fly, draw } from 'svelte/transition';

	let enableTransition = false;

	onMount(() => {
		setTimeout(() => {
			enableTransition = true;
		}, 1000);
	});

	const heartClick = () => {
		alert('Heart clicked');
	};

	function typewriter(node, { speed = 100 }) {
		const valid =
			node.childNodes.length === 1 &&
			node.childNodes[0].nodeType === Node.TEXT_NODE;

		if (!valid) {
			throw new Error(
				`This transition only works on elements with a single text node child`
			);
		}

		const text = node.textContent;
		const duration = text.length * speed;

		return {
			duration,
			tick: (t) => {
				const i = ~~(text.length * t);
				node.textContent = text.slice(0, i);
			},
		};
	}
</script>

<style>
	:global(body) {
		background-image: url('../images/hearts-pattern.svg');
	}
	main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	}

	h1 {
		color: #a311cf;
		font-size: 4em;
		font-weight: 100;
		font-family: 'Yellowtail', cursive;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}

	.mani-khia {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.heart {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	img {
		object-fit: contain;
		padding: 2em;
	}
</style>

<main>
	{#if enableTransition}
		<h1 in:typewriter>Happy 2nd Anniversary!</h1>
		<div class="mani-khia">
			<div>
				<img
					in:fly={{ x: -100, duration: 1000, delay: 2500 }}
					src="images/khia.png"
					alt="Khia" />
			</div>
			<div class="heart">
				<img
					in:fly={{ y: -200, duration: 1000, delay: 2500 }}
					on:click={heartClick}
					src="images/heart.png"
					alt="Heart" />
			</div>
			<div>
				<img
					in:fly={{ x: 100, duration: 1000, delay: 2500 }}
					src="images/mani.png"
					alt="Mani" />
			</div>
		</div>
	{/if}
</main>
